
import { defineComponent, reactive, ref, Ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { DataService } from "@/services/data.service";
import Data from "@/models/Data";
import { SessionService } from "@/services/session.service";
import Session from "@/models/Session";
import Header from "@/components/Header.vue";
import ImageViewer from "@/components/ImageViewer.vue";
import {
  DATETIME_FORMAT,
  EvaluationResultStatus,
  AssessmentType,
} from "@/models/ValueObjects";
import moment from "moment";
import lodash from "lodash";
import TheLoader from "@/components/TheLoader.vue";

interface Assessment {
  [key: number]: string;
}

export default defineComponent({
  name: "Session",
  props: {
    sessionId: {
      type: String,
      required: true,
    },
  },
  components: {
    Header,
    ImageViewer,
    TheLoader,
  },
  async beforeRouteEnter(to, from, next) {
    await SessionService.getSessionById(to.params.sessionId as string).then(
      (response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next((vm: any) => {
          vm.currentSession = response.data.data;
          for (var section of vm.currentSession.resultTemplate.sections) {
            for (var measurement of section.measurements) {
              vm.imageRef[measurement.name + " Ref"] =
                ref<typeof ImageViewer | undefined>();
            }
          }
        });
      }
    );
  },
  setup(props) {
    const NORMAL_ASSESSMENT: Assessment = reactive({
      1: "Normal",
      2: "Abnormal",
      3: "Unsure",
    });
    const VISUAL_ASSESSMENT: Assessment = reactive({
      1: "Visualized",
      2: "Not Visualized",
      3: "Unsure",
    });
    const PRESENT_ASSESSMENT: Assessment = reactive({
      1: "Present",
      2: "Absent",
      3: "Unsure",
    });

    const router = useRouter();

    const currentSession = ref(new Session({}));
    const currentData = ref(new Data({}));

    const allDataCount = ref(0);
    const currentProgress = ref(0);

    const imageRef: Record<string, Ref<typeof ImageViewer | undefined>> = {};

    const activeImageRadio = ref("");

    const isLoadedImage = ref(false);

    const errorMessage = reactive({
      submit: "",
    });

    const clearMeasurement = (parameter?: string) => {
      if (currentData.value.result) {
        for (var section of currentData.value.result.sections) {
          for (var measurement of section.measurements) {
            if (!parameter || parameter == measurement.name) {
              measurement.firstPoint.x = null;
              measurement.firstPoint.y = null;
              measurement.secondPoint.x = null;
              measurement.secondPoint.y = null;
              measurement.measurement = null;
              imageRef[measurement.name + " Ref"]?.value?.drawCanvas();
            }
          }
        }
      }
    };

    interface GetDataFilter {
      page: number;
      perPage: number;
      isFinished?: boolean;
    }

    const getData = async (filter: GetDataFilter) => {
      currentData.value = new Data({});
      await DataService.getDataListByFilter(props.sessionId, filter).then(
        (response) => {
          currentData.value = response.data.data[0];
          if (currentData.value) {
            currentProgress.value = currentData.value.index + 1;
            errorMessage.submit = "";
            // Since Object.assign or manual reassigning only shallow copies the source object, deep copies will force
            // assiging the target object will the deeply cloned value with no reactivity involved.
            if (currentData.value.result == null) {
              currentData.value.setData({
                result: lodash.cloneDeep(currentSession.value.resultTemplate),
              });
            }
            if (currentData.value.result) {
              currentData.value.result.startAt =
                moment().format(DATETIME_FORMAT);
              activeImageRadio.value =
                currentData.value.result.sections[0]?.measurements[0]?.name;
            }
          }
        }
      );
      isLoadedImage.value = true;
    };

    interface GetDataCountFilter {
      isFinished?: boolean;
    }

    const getDataCount = async (
      filter: GetDataCountFilter
    ): Promise<number> => {
      let count = 0;
      await DataService.getDataCountByFilter(props.sessionId, filter).then(
        (response) => {
          count = response.data.data;
        }
      );
      return count;
    };

    const previous = async () => {
      isLoadedImage.value = false;
      currentProgress.value -= 1;
      errorMessage.submit = "";
      try {
        await getData({
          page: currentProgress.value,
          perPage: 1,
        });
      } catch (err) {
        errorMessage.submit = err.response.data;
      }
    };

    const next = async () => {
      isLoadedImage.value = false;
      currentProgress.value += 1;
      errorMessage.submit = "";
      try {
        await getData({
          page: currentProgress.value,
          perPage: 1,
        });
      } catch (err) {
        errorMessage.submit = err.response.data;
      }
    };

    const submit = async (submitStatus = { isSkipping: false }) => {
      isLoadedImage.value = false;
      errorMessage.submit = "";
      if (currentData.value.result) {
        currentData.value.result.finishAt = moment().format(DATETIME_FORMAT);
        if (submitStatus.isSkipping) {
          currentData.value.result.sections =
            currentSession.value.resultTemplate.sections;
          currentData.value.result.status = EvaluationResultStatus.REJECTED;
        } else {
          currentData.value.result.status = EvaluationResultStatus.SUBMITTED;
        }

        try {
          await DataService.patchResult(
            currentData.value.id,
            currentData.value.result
          );
          if (allDataCount.value == currentProgress.value) {
            await SessionService.patchSubmittedAt(currentSession.value.id);
            router.push({ name: "Dashboard" });
            return;
          } else {
            currentProgress.value += 1;
            await getData({
              page: currentProgress.value,
              perPage: 1,
            });
          }
        } catch (err) {
          errorMessage.submit = err.response.data;
        }
      }
    };

    onMounted(async () => {
      allDataCount.value = await getDataCount({});
      const finishedDataCount = await getDataCount({ isFinished: true });
      if (finishedDataCount < allDataCount.value) {
        await getData({
          page: 1,
          perPage: 1,
          isFinished: false,
        });
      } else {
        await getData({
          page: 1,
          perPage: 1,
        });
      }
    });

    return {
      lodash,
      router,
      comment: computed({
        get() {
          return currentData.value.result?.comment ?? null;
        },
        set(value: string | null) {
          if (currentData.value.result)
            currentData.value.result.comment = value;
        },
      }),
      currentSession,
      currentData,
      imageRef,
      activeImageRadio,
      isLoadedImage,
      allDataCount,
      currentProgress,
      errorMessage,
      clearMeasurement,
      previous,
      next,
      submit,
      EvaluationResultStatus,
      AssessmentType,
      NORMAL_ASSESSMENT,
      VISUAL_ASSESSMENT,
      PRESENT_ASSESSMENT,
    };
  },
});
