
export default {
  props: {
    text: {
      required: false,
      type: String,
      default: "Loading",
    },
  },
};
