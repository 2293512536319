<template>
  <div>
    <div class="resource row" ref="resourceDivRef">
      <img
        :src="`data:image/png;base64,` + image"
        alt="altImg"
        class="img-fluid"
        ref="imageRef"
        @load="drawCanvas()"
      />
      <div class="col align-self-center" v-if="!image">
        <the-loader :text="`Loading image`"></the-loader>
      </div>
      <canvas
        ref="canvasRef"
        :style="{
          filter: `brightness(${canvasInfo.visualization.brightness}) contrast(${canvasInfo.visualization.contrast}`,
        }"
      ></canvas>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row row-cols-1 row-cols-md-2">
            <the-image-manipulation-slider
              :manipulationName="`Brightness`"
              :manipulationValue="Number(canvasInfo.visualization.brightness)"
              :maxValue="2.0"
              @inputChange="canvasInfo.visualization.brightness = $event"
            ></the-image-manipulation-slider>
            <the-image-manipulation-slider
              :manipulationName="`Contrast`"
              :manipulationValue="Number(canvasInfo.visualization.contrast)"
              :maxValue="2.0"
              @inputChange="canvasInfo.visualization.contrast = $event"
            ></the-image-manipulation-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
  toRef,
} from "vue";
import Data from "@/models/Data";
import { Measurement } from "@/models/Session";
import TheLoader from "./TheLoader.vue";
import TheImageManipulationSlider from "./TheImageManipulationSlider.vue";
import _ from "lodash";

interface Point {
  x: number;
  y: number;
  isDragging: boolean;
}

export default defineComponent({
  components: {
    TheLoader,
    TheImageManipulationSlider,
  },
  props: {
    measurement: {
      type: Object as PropType<Measurement>,
      required: true,
    },
    data: {
      type: Object as PropType<Data>,
      required: true,
    },
  },
  setup(props, context) {
    const MIN_PIXEL_RESOLUTION = 0.01;
    const MAX_PIXEL_RESOLUTION = 1;

    const BOOTSTRAP_ROW_PADDING = 12; //Bootstrap adds a x-axis padding of 12 to the content inside a row.

    const DEFAULT_ZOOM = 1;
    const MAX_ZOOM = 4;
    const MIN_ZOOM = 1;
    const ZOOM_STEP = 0.1;

    const MARKER_SCALE = computed(() =>
      props.data.pixelResolution > MIN_PIXEL_RESOLUTION &&
      props.data.pixelResolution < MAX_PIXEL_RESOLUTION
        ? props.data.pixelResolution * 1500
        : 150
    ); //To scale the marker's lines accordingly to the size of the image.
    const DASH_SIZE_SCALE = computed(() =>
      props.data.pixelResolution > MIN_PIXEL_RESOLUTION &&
      props.data.pixelResolution < MAX_PIXEL_RESOLUTION
        ? props.data.pixelResolution * 4500
        : 450
    ); //To scale the dash size accordingly to the size of the image.
    const DASH_OFFSET_SCALE = computed(() =>
      props.data.pixelResolution > MIN_PIXEL_RESOLUTION &&
      props.data.pixelResolution < MAX_PIXEL_RESOLUTION
        ? props.data.pixelResolution * 1500
        : 150
    ); //To scale the dash offset accordingly to the size of the image.

    const resourceDivRef: Ref<HTMLDivElement | undefined> = ref();
    const imageRef: Ref<HTMLImageElement | undefined> = ref();
    const canvasRef: Ref<HTMLCanvasElement | undefined> = ref();

    const dragInfo = reactive({
      isDragging: false,
      startX: null,
      startY: null,
    } as {
      isDragging: boolean;
      startX: number | null;
      startY: number | null;
    });

    const canvasInfo = reactive({
      drawableCaliper: true,
      scale: DEFAULT_ZOOM,
      zooming: false,
      mousePosDiff: false,
      drawPos: [0, 0],
      mousePos: [0, 0],
      zoomPos: [0, 0],
      visualization: {
        brightness: 1,
        contrast: 1,
      },
    });

    const markers = computed(() => {
      const points: Array<Point> = [];
      if (props.measurement.firstPoint.x && props.measurement.firstPoint.y)
        points.push({
          x: props.measurement.firstPoint.x,
          y: props.measurement.firstPoint.y,
          isDragging: false,
        });
      if (props.measurement.secondPoint.x && props.measurement.secondPoint.y)
        points.push({
          x: props.measurement.secondPoint.x,
          y: props.measurement.secondPoint.y,
          isDragging: false,
        });
      return points;
    });

    const initializeCanvas = (w: number, h: number, x: number, y: number) => {
      if (canvasRef.value && imageRef.value) {
        canvasRef.value.width = imageRef.value.width;
        canvasRef.value.height = imageRef.value.height;
        const canvasContext = canvasRef.value.getContext("2d");
        if (canvasContext) {
          canvasContext.drawImage(imageRef.value, x, y, w, h);
          return canvasContext;
        }
      }
    };

    const drawCanvas = (calipers = markers.value) => {
      if (canvasRef.value && imageRef.value && resourceDivRef.value) {
        if (canvasInfo.scale == DEFAULT_ZOOM) {
          canvasInfo.drawPos = [
            imageRef.value.width / 2,
            imageRef.value.height / 2,
          ];
        }
        resourceDivRef.value.style.height =
          String(imageRef.value.height) + "px";

        let w = imageRef.value.width * canvasInfo.scale;
        let h = imageRef.value.height * canvasInfo.scale;
        let minusX = canvasRef.value.width - w;
        let minusY = canvasRef.value.height - h;

        if (canvasInfo.zooming) {
          if (canvasInfo.mousePosDiff == true && canvasInfo.scale > 1.2) {
            canvasInfo.zoomPos[0] +=
              -canvasRef.value.width *
              ZOOM_STEP *
              (canvasInfo.mousePos[0] / canvasRef.value.width);
            canvasInfo.zoomPos[1] +=
              -canvasRef.value.height *
              ZOOM_STEP *
              (canvasInfo.mousePos[1] / canvasRef.value.height);
          } else {
            canvasInfo.zoomPos[0] =
              minusX * (canvasInfo.mousePos[0] / canvasRef.value.width);
            canvasInfo.zoomPos[1] =
              minusY * (canvasInfo.mousePos[1] / canvasRef.value.height);
          }
        }

        let x =
          canvasInfo.drawPos[0] * canvasInfo.scale -
          w / 2 +
          canvasInfo.zoomPos[0];
        let y =
          canvasInfo.drawPos[1] * canvasInfo.scale -
          h / 2 +
          canvasInfo.zoomPos[1];

        x = Math.min(0, Math.max(canvasRef.value.width - w, x));
        y = Math.min(0, Math.max(canvasRef.value.height - h, y));

        const canvasContext = initializeCanvas(w, h, x, y);
        if (canvasContext) {
          for (let i = 0; i < calipers.length; i++) {
            const relX = calipers[i].x * w + x;
            const relY = calipers[i].y * h + y;

            drawMarker(canvasContext, { x: relX, y: relY }, w);
          }
          if (calipers.length == 2) {
            drawMarkerConnections(canvasContext, calipers, w, h, x, y);
          }
        }
      }
    };

    const updateMarkers = () => {
      if (imageRef.value) {
        if (markers.value.length == 2) {
          let w = imageRef.value.width * canvasInfo.scale;
          let h = imageRef.value.height * canvasInfo.scale;

          context.emit("pointsChange", markers.value);
          const a = (markers.value[0].x - markers.value[1].x) * w;
          const b = (markers.value[0].y - markers.value[1].y) * h;
          const c = Math.sqrt(a * a + b * b);
          context.emit(
            "measurementChange",
            ((c * props.data.pixelResolution) /
              (imageRef.value.width * canvasInfo.scale)) *
              imageRef.value.naturalWidth
          );
        }
      }
    };

    const drawMarker = (
      canvasContext: CanvasRenderingContext2D,
      marker: { x: number; y: number },
      w: number
    ) => {
      canvasContext.lineWidth = 2;
      canvasContext.moveTo(marker.x, marker.y - w / MARKER_SCALE.value);
      canvasContext.lineTo(
        marker.x,
        marker.y - ((1 / 3) * w) / MARKER_SCALE.value
      );
      canvasContext.moveTo(
        marker.x,
        marker.y + ((1 / 3) * w) / MARKER_SCALE.value
      );
      canvasContext.lineTo(marker.x, marker.y + w / MARKER_SCALE.value);
      canvasContext.moveTo(marker.x - w / MARKER_SCALE.value, marker.y);
      canvasContext.lineTo(
        marker.x - ((1 / 3) * w) / MARKER_SCALE.value,
        marker.y
      );
      canvasContext.moveTo(
        marker.x + ((1 / 3) * w) / MARKER_SCALE.value,
        marker.y
      );
      canvasContext.lineTo(marker.x + w / MARKER_SCALE.value, marker.y);
      canvasContext.strokeStyle = "yellow";
      canvasContext.stroke();
    };

    const drawMarkerConnections = (
      canvasContext: CanvasRenderingContext2D,
      markers: { x: number; y: number }[],
      w: number,
      h: number,
      x: number,
      y: number
    ) => {
      canvasContext.lineWidth = 1;
      canvasContext.beginPath();
      canvasContext.setLineDash([
        w / DASH_SIZE_SCALE.value,
        w / DASH_OFFSET_SCALE.value,
      ]);
      if (canvasInfo.drawPos[0] != 0 && canvasInfo.drawPos[1] != 0) {
        canvasContext.moveTo(markers[0].x * w + x, markers[0].y * h + y);
        canvasContext.lineTo(markers[1].x * w + x, markers[1].y * h + y);
      } else {
        canvasContext.moveTo(markers[0].x * w, markers[0].y * h);
        canvasContext.lineTo(markers[1].x * w, markers[1].y * h);
      }
      canvasContext.stroke();
    };

    const placeCaliper = (ev: MouseEvent) => {
      if (
        canvasRef.value &&
        (props.measurement.isReliable == true ||
          props.measurement.isReliable == null)
      ) {
        const canvasContext = canvasRef.value.getContext("2d");
        const canvasRect = canvasRef.value.getBoundingClientRect();

        let w = canvasRef.value.width * canvasInfo.scale;
        let h = canvasRef.value.height * canvasInfo.scale;

        let x =
          canvasInfo.drawPos[0] * canvasInfo.scale -
          w / 2 +
          canvasInfo.zoomPos[0];
        let y =
          canvasInfo.drawPos[1] * canvasInfo.scale -
          h / 2 +
          canvasInfo.zoomPos[1];

        const relX = ev.clientX - canvasRect.left - BOOTSTRAP_ROW_PADDING;
        const relY = ev.clientY - canvasRect.top;

        if (canvasContext && canvasInfo.drawableCaliper) {
          if (markers.value.length < 2) {
            canvasInfo.drawPos[0] =
              canvasInfo.drawPos[0] >=
                canvasRef.value.width / 2 -
                  canvasInfo.zoomPos[0] / canvasInfo.scale ||
              canvasInfo.drawPos[0] == 0
                ? canvasRef.value.width / 2 -
                  canvasInfo.zoomPos[0] / canvasInfo.scale
                : canvasInfo.drawPos[0];
            canvasInfo.drawPos[1] =
              canvasInfo.drawPos[1] >=
                canvasRef.value.height / 2 -
                  canvasInfo.zoomPos[1] / canvasInfo.scale ||
              canvasInfo.drawPos[1] == 0
                ? canvasRef.value.height / 2 -
                  canvasInfo.zoomPos[0] / canvasInfo.scale
                : canvasInfo.drawPos[1];

            markers.value.push({
              x: (relX - x) / w,
              y: (relY - y) / h,
              isDragging: false,
            });
          }
          updateMarkers();
        }
        drawCanvas();
      }
    };

    const holdCanvas = (ev: MouseEvent) => {
      if (canvasRef.value) {
        ev.preventDefault();
        ev.stopPropagation();

        let w = canvasRef.value.width * canvasInfo.scale;
        let h = canvasRef.value.height * canvasInfo.scale;

        let x =
          canvasInfo.drawPos[0] * canvasInfo.scale -
          w / 2 +
          canvasInfo.zoomPos[0];
        let y =
          canvasInfo.drawPos[1] * canvasInfo.scale -
          h / 2 +
          canvasInfo.zoomPos[1];

        const canvasRect = canvasRef.value.getBoundingClientRect();

        const relX = ev.clientX - canvasRect.left - BOOTSTRAP_ROW_PADDING;
        const relY = ev.clientY - canvasRect.top;

        dragInfo.isDragging = true;
        canvasInfo.drawableCaliper = true;
        for (let i = 0; i < markers.value.length; i++) {
          const marker = markers.value[i];
          if (
            relX > (marker.x - 1 / MARKER_SCALE.value) * w + x &&
            relX < (marker.x + 1 / MARKER_SCALE.value) * w + x &&
            relY > (marker.y - 1 / MARKER_SCALE.value) * h + y &&
            relY < (marker.y + 1 / MARKER_SCALE.value) * h + y
          ) {
            dragInfo.isDragging = true;
            markers.value[i].isDragging = true;
          }
        }
        dragInfo.startX = relX;
        dragInfo.startY = relY;
      }
    };

    const releaseCanvas = (ev: MouseEvent) => {
      if (canvasRef.value) {
        ev.preventDefault();
        ev.stopPropagation();

        dragInfo.isDragging = false;
        for (let i = 0; i < markers.value.length; i++) {
          markers.value[i].isDragging = false;
        }
        updateMarkers();
      }
    };

    const moveCanvas = (ev: MouseEvent) => {
      if (canvasRef.value) {
        hoverCaliper(ev);

        const canvasRect = canvasRef.value.getBoundingClientRect();

        const relX = ev.clientX - canvasRect.left - BOOTSTRAP_ROW_PADDING;
        const relY = ev.clientY - canvasRect.top;

        canvasInfo.drawPos[0] = Math.min(
          canvasRef.value.width / 2 - canvasInfo.zoomPos[0] / canvasInfo.scale,
          Math.max(
            canvasRef.value.width / canvasInfo.scale -
              canvasRef.value.width / 2 -
              canvasInfo.zoomPos[0] / canvasInfo.scale,
            canvasInfo.drawPos[0]
          )
        );
        canvasInfo.drawPos[1] = Math.min(
          canvasRef.value.height / 2 - canvasInfo.zoomPos[1] / canvasInfo.scale,
          Math.max(
            canvasRef.value.height / canvasInfo.scale -
              canvasRef.value.height / 2 -
              canvasInfo.zoomPos[1] / canvasInfo.scale,
            canvasInfo.drawPos[1]
          )
        );

        if (dragInfo.isDragging) {
          ev.preventDefault();
          ev.stopPropagation();

          setTimeout(() => {
            canvasInfo.drawableCaliper = false;
          }, 70);

          if (dragInfo.startX && dragInfo.startY) {
            const dX = relX - dragInfo.startX;
            const dY = relY - dragInfo.startY;
            let marker = null;
            for (let i = 0; i < markers.value.length; i++) {
              if (markers.value[i].isDragging) {
                marker = markers.value[i];
              }
            }
            if (marker) {
              canvasRef.value.style.cursor = "grabbing";
              marker.x += dX / (canvasRef.value.width * canvasInfo.scale);
              marker.y += dY / (canvasRef.value.height * canvasInfo.scale);
            } else {
              canvasInfo.drawPos = [
                canvasInfo.drawPos[0] + dX,
                canvasInfo.drawPos[1] + dY,
              ];
              if (canvasInfo.scale == DEFAULT_ZOOM) {
                canvasInfo.drawPos = [
                  canvasRef.value.width / 2,
                  canvasRef.value.height / 2,
                ];
              }
            }

            drawCanvas();
            dragInfo.startX = relX;
            dragInfo.startY = relY;
          }
        }
      }
    };

    const hoverCaliper = (ev: MouseEvent) => {
      if (canvasRef.value) {
        const canvasRect = canvasRef.value.getBoundingClientRect();

        let w = canvasRef.value.width * canvasInfo.scale;
        let h = canvasRef.value.height * canvasInfo.scale;

        let x =
          canvasInfo.drawPos[0] * canvasInfo.scale -
          w / 2 +
          canvasInfo.zoomPos[0];
        let y =
          canvasInfo.drawPos[1] * canvasInfo.scale -
          h / 2 +
          canvasInfo.zoomPos[1];

        const relX = ev.clientX - canvasRect.left - BOOTSTRAP_ROW_PADDING;
        const relY = ev.clientY - canvasRect.top;

        if (markers.value.length < 2) {
          canvasRef.value.style.cursor = "pointer";
          const placeholderMarker = {
            x: (relX - x) / w,
            y: (relY - y) / h,
            isDragging: false,
          };
          drawCanvas(_.concat(markers.value, placeholderMarker));
        } else canvasRef.value.style.cursor = "default";
        for (let i = 0; i < markers.value.length; i++) {
          const marker = markers.value[i];
          if (
            relX > (marker.x - 1 / MARKER_SCALE.value) * w + x &&
            relX < (marker.x + 1 / MARKER_SCALE.value) * w + x &&
            relY > (marker.y - 1 / MARKER_SCALE.value) * h + y &&
            relY < (marker.y + 1 / MARKER_SCALE.value) * h + y
          ) {
            canvasRef.value.style.cursor = "grab";
          }
        }
      }
    };

    const zoomCanvas = (ev: WheelEvent) => {
      if (canvasRef.value) {
        const canvasRect = canvasRef.value.getBoundingClientRect();
        const relX = ev.clientX - canvasRect.left - BOOTSTRAP_ROW_PADDING;
        const relY = ev.clientY - canvasRect.top;

        canvasInfo.zooming = true;
        const zoomIn = () => {
          if (canvasInfo.scale < MAX_ZOOM) {
            // if (
            //   relX == canvasInfo.mousePos[0] &&
            //   relY == canvasInfo.mousePos[1]
            // ) {
            //   canvasInfo.mousePosDiff = false;
            // } else {
            //   canvasInfo.mousePosDiff = true;
            // }
            if (canvasInfo.scale == DEFAULT_ZOOM) {
              canvasInfo.mousePos = [relX, relY];
            }

            canvasInfo.scale += ZOOM_STEP;
            drawCanvas();
          }
        };
        const zoomOut = () => {
          if (canvasInfo.scale > MIN_ZOOM) {
            canvasInfo.scale -= ZOOM_STEP;
            drawCanvas();
          }
        };
        const zoom = () => {
          if (ev.deltaY < 0) {
            zoomIn();
          } else {
            zoomOut();
          }
        };
        zoom();
        canvasInfo.zooming = false;
        ev.preventDefault();
        ev.stopPropagation();
      }
    };

    onMounted(() => {
      if (canvasRef.value) {
        canvasRef.value.onclick = placeCaliper;
        canvasRef.value.onmousedown = holdCanvas;
        canvasRef.value.onmouseup = releaseCanvas;
        canvasRef.value.onmousemove = moveCanvas;
        canvasRef.value.onwheel = zoomCanvas;
      }
      window.addEventListener("resize", () => drawCanvas());
    });

    return {
      resourceDivRef,
      imageRef,
      canvasRef,
      markers,
      drawCanvas,
      canvasInfo,
      image: toRef(props.data, "image"),
    };
  },
});
</script>

<style scoped>
.resource {
  position: relative;
  width: 100%;
  margin: 0px;
}
img {
  position: absolute;
  object-fit: contain;
  visibility: hidden;
  z-index: 1;
}

canvas {
  position: absolute;
  width: 100%;
  z-index: 2;
}
</style>
