
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    title: String,
    currentProgress: String,
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
});
