
import { defineComponent, ref, toRef } from "vue";
export default defineComponent({
  props: {
    manipulationName: {
      type: String,
      required: true,
    },
    manipulationValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const name = ref("");
    const value = ref(0);
    name.value = toRef(props, "manipulationName").value;
    value.value = toRef(props, "manipulationValue").value;
    const onInput = () => {
      context.emit("inputChange", value.value);
    };
    return { name, value, onInput };
  },
});
