<template>
  <div class="mx-5 bg-white rounded shadow">
    <Header
      :title="`${lodash.startCase(
        lodash.toLower(currentSession.plane)
      )} Plane Session ${currentSession.sessionNo}`"
      :currentProgress="`${currentProgress}/${allDataCount}`"
    >
      <template v-slot:previous>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!isLoadedImage || currentProgress == 1"
          @click="previous()"
        >
          Previous
        </button>
      </template>
      <template v-slot:next>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!isLoadedImage || currentProgress == allDataCount"
          @click="next()"
        >
          Next
        </button>
      </template>
      <template v-slot:reject>
        <button
          type="button"
          class="btn btn-danger"
          :disabled="!isLoadedImage"
          @click="submit({ isSkipping: true })"
        >
          Reject
        </button>
      </template>
      <template v-slot:submit>
        <button
          type="submit"
          form="sessionForm"
          class="btn btn-success"
          :disabled="!isLoadedImage"
        >
          Submit
        </button>
      </template>
    </Header>
    <div
      class="alert alert-danger alert-dismissible show"
      role="alert"
      v-if="errorMessage.submit"
    >
      <h4 class="alert-heading">ERROR</h4>
      <strong>Message:</strong> {{ errorMessage.submit }}
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="errorMessage.submit = ''"
      ></button>
    </div>
    <div class="panel row row-cols-1 row-cols-lg-2">
      <!-- LEFT PANEL -->
      <div class="left-panel col-lg-8 border">
        <!-- <div class="row row-cols-1 row-cols-lg-2 mx-auto my-3">
          <div class="col-lg-9 container">
            <h4>Measurements</h4>
            <div
              v-for="resultSection of currentData.result?.sections"
              :key="resultSection.index"
            >
              <div
                v-for="resultSectionMeasurement of resultSection.measurements"
                :key="
                  'result ' +
                  resultSection.index +
                  'measurement ' +
                  resultSectionMeasurement.index
                "
                class="pb-2 form-check form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="measurementRadioOptions"
                  :id="'left radio ' + resultSectionMeasurement.name"
                  :value="resultSectionMeasurement.name"
                  v-model="activeImageRadio"
                />
                <label
                  class="form-check-label"
                  :for="'left radio ' + resultSectionMeasurement.name"
                  >{{ resultSectionMeasurement.name }}</label
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3 m-auto">
            <button
              class="btn btn-outline-danger"
              type="button"
              @click="clearMeasurement(activeImageRadio)"
            >
              Clear currently selected measurement
            </button>
          </div>
        </div> -->
        <figure class="text-center mt-2">
          <h3
            :class="
              currentData.result?.status == EvaluationResultStatus.SUBMITTED
                ? 'text-success'
                : currentData.result?.status == EvaluationResultStatus.REJECTED
                ? 'text-danger'
                : 'text-secondary'
            "
          >
            {{ lodash.startCase(lodash.toLower(currentData.result?.status)) }}
          </h3>
        </figure>
        <div v-if="currentData.image">
          <div
            v-for="resultSection of currentData.result?.sections"
            :key="'result image ' + resultSection.index"
          >
            <div
              v-for="resultSectionMeasurement of resultSection.measurements"
              :key="
                'result image ' +
                resultSection.index +
                'measurement ' +
                resultSectionMeasurement.index
              "
            >
              <image-viewer
                v-if="activeImageRadio == resultSectionMeasurement.name"
                :measurement="resultSectionMeasurement"
                :data="currentData"
                @pointsChange="
                  resultSectionMeasurement.firstPoint = $event[0];
                  resultSectionMeasurement.secondPoint = $event[1];
                "
                @measurementChange="
                  resultSectionMeasurement.measurement = $event
                "
                :ref="imageRef[resultSectionMeasurement.name + ' Ref']"
              ></image-viewer>
            </div>
          </div>
        </div>
        <the-loader v-else :text="`Loading image`"></the-loader>
      </div>
      <!-- </div> -->
      <!-- RIGHT PANEL -->
      <div class="right-panel pt-3 col-lg-4 col-md-12 border">
        <form id="sessionForm" @submit.prevent="submit()" class="form p-2">
          <div
            v-for="resultSection of currentData.result?.sections"
            :key="'result value ' + resultSection.index"
            class="pb-4"
          >
            <h5 class="fw-bold text-start">{{ resultSection.name }}</h5>
            <div class="pb-2 border-bottom">
              <fieldset :id="resultSection.name" class="form-group">
                <div
                  v-for="resultSectionMeasurement of resultSection.measurements"
                  :key="
                    'result value ' +
                    resultSection.index +
                    'measumrent ' +
                    resultSectionMeasurement.index
                  "
                  class="pb-2"
                >
                  <div class="row row-cols-2 pb-1">
                    <div class="col-auto text-start">
                      <span>
                        {{ resultSectionMeasurement.name }}
                      </span>
                    </div>
                  </div>
                  <div class="row row-cols-3 align-items-center">
                    <div class="col-auto">
                      <div class="input-group input-group-sm">
                        <input
                          class="form-control"
                          type="text"
                          autocomplete="off"
                          :id="'measurement ' + resultSectionMeasurement.name"
                          :aria-label="resultSectionMeasurement.name"
                          :aria-describedby="resultSectionMeasurement.name"
                          :value="
                            resultSectionMeasurement.isReliable == false
                              ? null
                              : resultSectionMeasurement.measurement
                          "
                          :style="[
                            activeImageRadio === resultSectionMeasurement.name
                              ? { 'background-color': '#96d3ec' }
                              : '',
                          ]"
                          @click="
                            activeImageRadio = resultSectionMeasurement.name
                          "
                          :required="
                            resultSectionMeasurement.isReliable == true ||
                            resultSectionMeasurement.isReliable == null
                          "
                          onkeydown="return false;"
                        />
                        <span
                          class="input-group-text"
                          :id="'unit ' + resultSectionMeasurement.name"
                          >{{ resultSectionMeasurement.unit }}</span
                        >
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm"
                        @click="clearMeasurement(resultSectionMeasurement.name)"
                      >
                        Clear
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :name="
                            resultSection.name +
                            resultSectionMeasurement.name +
                            ' radio reliability'
                          "
                          :id="
                            resultSection.name +
                            resultSectionMeasurement.name +
                            ' radio unreliable'
                          "
                          :false-value="true"
                          :true-value="false"
                          @input="
                            clearMeasurement(resultSectionMeasurement.name)
                          "
                          v-model="resultSectionMeasurement.isReliable"
                        />
                        <label
                          class="form-check-label"
                          :for="
                            resultSection.name +
                            resultSectionMeasurement.name +
                            ' radio unreliable'
                          "
                          >Unreliable</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="resultSectionAssessment of resultSection.assessments"
                  :key="
                    'result value ' +
                    resultSection +
                    'assessment ' +
                    resultSectionAssessment.index
                  "
                  class="row row-cols-2 align-items-center"
                >
                  <p class="col-form-label col-5 text-start">
                    {{ resultSectionAssessment.name }}
                  </p>
                  <div
                    v-if="
                      resultSectionAssessment.type == AssessmentType.PRESENT
                    "
                    class="col-auto text-start"
                  >
                    <div
                      class="form-check form-check-inline"
                      v-for="(name, value) of PRESENT_ASSESSMENT"
                      v-bind:key="(name, value)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="
                          resultSection.name + resultSectionAssessment.name
                        "
                        :id="
                          resultSection.name +
                          resultSectionAssessment.name +
                          name +
                          'Radio'
                        "
                        :value="value"
                        v-model="resultSectionAssessment.chosenOption"
                        required
                      />
                      <label
                        class="form-check-label"
                        :for="resultSectionAssessment.name + name + 'Radio'"
                      >
                        {{ name }}
                      </label>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      resultSectionAssessment.type == AssessmentType.NORMAL
                    "
                    class="col-auto text-start"
                  >
                    <div
                      class="form-check form-check-inline"
                      v-for="(name, value) of NORMAL_ASSESSMENT"
                      v-bind:key="(name, value)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="
                          resultSection.name + resultSectionAssessment.name
                        "
                        :id="
                          resultSection.name +
                          resultSectionAssessment.name +
                          name +
                          'Radio'
                        "
                        :value="value"
                        v-model="resultSectionAssessment.chosenOption"
                        required
                      />
                      <label
                        class="form-check-label"
                        :for="resultSectionAssessment.name + name + 'Radio'"
                      >
                        {{ name }}
                      </label>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      resultSectionAssessment.type == AssessmentType.VISUAL
                    "
                    class="col-auto text-start"
                  >
                    <div
                      class="form-check form-check-inline"
                      v-for="(name, value) of VISUAL_ASSESSMENT"
                      v-bind:key="(name, value)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="
                          resultSection.name + resultSectionAssessment.name
                        "
                        :id="
                          resultSection.name +
                          resultSectionAssessment.name +
                          name +
                          'Radio'
                        "
                        :value="value"
                        v-model="resultSectionAssessment.chosenOption"
                        required
                      />
                      <label
                        class="form-check-label"
                        :for="resultSectionAssessment.name + name + 'Radio'"
                      >
                        {{ name }}
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="pb-4">
            <h5 class="fw-bold text-start">Comment</h5>
            <div class="pb-2">
              <fieldset id="comment" class="form-group">
                <div class="row align-items-center">
                  <textarea
                    class="form-control"
                    style="cursor: auto; background-color: white; width: 100%"
                    id="commentTextArea"
                    rows="3"
                    v-model="comment"
                  ></textarea>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, Ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { DataService } from "@/services/data.service";
import Data from "@/models/Data";
import { SessionService } from "@/services/session.service";
import Session from "@/models/Session";
import Header from "@/components/Header.vue";
import ImageViewer from "@/components/ImageViewer.vue";
import {
  DATETIME_FORMAT,
  EvaluationResultStatus,
  AssessmentType,
} from "@/models/ValueObjects";
import moment from "moment";
import lodash from "lodash";
import TheLoader from "@/components/TheLoader.vue";

interface Assessment {
  [key: number]: string;
}

export default defineComponent({
  name: "Session",
  props: {
    sessionId: {
      type: String,
      required: true,
    },
  },
  components: {
    Header,
    ImageViewer,
    TheLoader,
  },
  async beforeRouteEnter(to, from, next) {
    await SessionService.getSessionById(to.params.sessionId as string).then(
      (response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next((vm: any) => {
          vm.currentSession = response.data.data;
          for (var section of vm.currentSession.resultTemplate.sections) {
            for (var measurement of section.measurements) {
              vm.imageRef[measurement.name + " Ref"] =
                ref<typeof ImageViewer | undefined>();
            }
          }
        });
      }
    );
  },
  setup(props) {
    const NORMAL_ASSESSMENT: Assessment = reactive({
      1: "Normal",
      2: "Abnormal",
      3: "Unsure",
    });
    const VISUAL_ASSESSMENT: Assessment = reactive({
      1: "Visualized",
      2: "Not Visualized",
      3: "Unsure",
    });
    const PRESENT_ASSESSMENT: Assessment = reactive({
      1: "Present",
      2: "Absent",
      3: "Unsure",
    });

    const router = useRouter();

    const currentSession = ref(new Session({}));
    const currentData = ref(new Data({}));

    const allDataCount = ref(0);
    const currentProgress = ref(0);

    const imageRef: Record<string, Ref<typeof ImageViewer | undefined>> = {};

    const activeImageRadio = ref("");

    const isLoadedImage = ref(false);

    const errorMessage = reactive({
      submit: "",
    });

    const clearMeasurement = (parameter?: string) => {
      if (currentData.value.result) {
        for (var section of currentData.value.result.sections) {
          for (var measurement of section.measurements) {
            if (!parameter || parameter == measurement.name) {
              measurement.firstPoint.x = null;
              measurement.firstPoint.y = null;
              measurement.secondPoint.x = null;
              measurement.secondPoint.y = null;
              measurement.measurement = null;
              imageRef[measurement.name + " Ref"]?.value?.drawCanvas();
            }
          }
        }
      }
    };

    interface GetDataFilter {
      page: number;
      perPage: number;
      isFinished?: boolean;
    }

    const getData = async (filter: GetDataFilter) => {
      currentData.value = new Data({});
      await DataService.getDataListByFilter(props.sessionId, filter).then(
        (response) => {
          currentData.value = response.data.data[0];
          if (currentData.value) {
            currentProgress.value = currentData.value.index + 1;
            errorMessage.submit = "";
            // Since Object.assign or manual reassigning only shallow copies the source object, deep copies will force
            // assiging the target object will the deeply cloned value with no reactivity involved.
            if (currentData.value.result == null) {
              currentData.value.setData({
                result: lodash.cloneDeep(currentSession.value.resultTemplate),
              });
            }
            if (currentData.value.result) {
              currentData.value.result.startAt =
                moment().format(DATETIME_FORMAT);
              activeImageRadio.value =
                currentData.value.result.sections[0]?.measurements[0]?.name;
            }
          }
        }
      );
      isLoadedImage.value = true;
    };

    interface GetDataCountFilter {
      isFinished?: boolean;
    }

    const getDataCount = async (
      filter: GetDataCountFilter
    ): Promise<number> => {
      let count = 0;
      await DataService.getDataCountByFilter(props.sessionId, filter).then(
        (response) => {
          count = response.data.data;
        }
      );
      return count;
    };

    const previous = async () => {
      isLoadedImage.value = false;
      currentProgress.value -= 1;
      errorMessage.submit = "";
      try {
        await getData({
          page: currentProgress.value,
          perPage: 1,
        });
      } catch (err) {
        errorMessage.submit = err.response.data;
      }
    };

    const next = async () => {
      isLoadedImage.value = false;
      currentProgress.value += 1;
      errorMessage.submit = "";
      try {
        await getData({
          page: currentProgress.value,
          perPage: 1,
        });
      } catch (err) {
        errorMessage.submit = err.response.data;
      }
    };

    const submit = async (submitStatus = { isSkipping: false }) => {
      isLoadedImage.value = false;
      errorMessage.submit = "";
      if (currentData.value.result) {
        currentData.value.result.finishAt = moment().format(DATETIME_FORMAT);
        if (submitStatus.isSkipping) {
          currentData.value.result.sections =
            currentSession.value.resultTemplate.sections;
          currentData.value.result.status = EvaluationResultStatus.REJECTED;
        } else {
          currentData.value.result.status = EvaluationResultStatus.SUBMITTED;
        }

        try {
          await DataService.patchResult(
            currentData.value.id,
            currentData.value.result
          );
          if (allDataCount.value == currentProgress.value) {
            await SessionService.patchSubmittedAt(currentSession.value.id);
            router.push({ name: "Dashboard" });
            return;
          } else {
            currentProgress.value += 1;
            await getData({
              page: currentProgress.value,
              perPage: 1,
            });
          }
        } catch (err) {
          errorMessage.submit = err.response.data;
        }
      }
    };

    onMounted(async () => {
      allDataCount.value = await getDataCount({});
      const finishedDataCount = await getDataCount({ isFinished: true });
      if (finishedDataCount < allDataCount.value) {
        await getData({
          page: 1,
          perPage: 1,
          isFinished: false,
        });
      } else {
        await getData({
          page: 1,
          perPage: 1,
        });
      }
    });

    return {
      lodash,
      router,
      comment: computed({
        get() {
          return currentData.value.result?.comment ?? null;
        },
        set(value: string | null) {
          if (currentData.value.result)
            currentData.value.result.comment = value;
        },
      }),
      currentSession,
      currentData,
      imageRef,
      activeImageRadio,
      isLoadedImage,
      allDataCount,
      currentProgress,
      errorMessage,
      clearMeasurement,
      previous,
      next,
      submit,
      EvaluationResultStatus,
      AssessmentType,
      NORMAL_ASSESSMENT,
      VISUAL_ASSESSMENT,
      PRESENT_ASSESSMENT,
    };
  },
});
</script>
<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
}

.form-control {
  cursor: pointer;
  background-color: lightgray;
  width: 6.9ch;
}

.row {
  margin: 0;
}

.right-panel {
  max-height: 100vh;
  height: auto;
  overflow: auto;
  justify-content: center;
}
</style>
