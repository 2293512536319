<template>
  <div class="col">
    <div class="row justify-content-center">
      <label class="col-auto">{{ name }}</label>
      <input
        type="text"
        class="col-auto"
        v-model="value"
        @input="onInput()"
        style="text-align: center"
      />
    </div>
    <div>
      <input
        type="range"
        class="form-range"
        min="0"
        :max="maxValue"
        step="0.01"
        v-model="value"
        @input="onInput()"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, toRef } from "vue";
export default defineComponent({
  props: {
    manipulationName: {
      type: String,
      required: true,
    },
    manipulationValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const name = ref("");
    const value = ref(0);
    name.value = toRef(props, "manipulationName").value;
    value.value = toRef(props, "manipulationValue").value;
    const onInput = () => {
      context.emit("inputChange", value.value);
    };
    return { name, value, onInput };
  },
});
</script>
