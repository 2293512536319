<template>
  <div
    class="
      row row-cols-1 row-cols-lg-3
      my-1
      py-2
      bg-light
      justify-content-around
      align-items-center
      border
    "
  >
    <div class="col-lg-auto">
      <h5 class="m-0">
        {{ title }}
      </h5>
    </div>
    <div class="col-lg-auto">
      <div
        class="row row-cols-3 mx-auto align-items-center justify-content-center"
      >
        <div class="col-auto col-lg-auto">
          <slot name="previous"></slot>
        </div>
        <p
          class="
            border border-primary border-2
            rounded-pill
            p-2
            my-2
            col-auto col-lg-auto
          "
        >
          Current Progress: {{ currentProgress }}
        </p>
        <div class="col-auto col-lg-auto">
          <slot name="next"></slot>
        </div>
      </div>
    </div>
    <div class="col-lg-auto">
      <div
        class="row row-cols-2 mx-auto align-items-center justify-content-center"
      >
        <div class="col-auto col-lg-auto">
          <slot name="reject"></slot>
        </div>
        <div class="col-auto col-lg-auto"><slot name="submit"></slot></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    title: String,
    currentProgress: String,
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
});
</script>
